.Home .lander {
    padding: 10px 0;
    text-align: center;
    padding-bottom: 80px;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}
  
.Home .lander p {
    color: #999;
}